import { ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export function perGallonsPriceValidator(min: number, max: number | null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isMax = max ? control.value <= max : true
    if (control.value == min || (control.value >= min && isMax && control.value)) {
      return null
    } else {
      return { perGallonsPriceValidator: true } // Don't change perGallonsPriceValidator key to somehting else. it can't detect by formControler validators
    }
  };
}

export function decimalPositionAllow(decimalPositionAllowed: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const numStr: string = control.value?.toString();
    const match: RegExpMatchArray | null = numStr?.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (match) {
      if (match[1]?.length > decimalPositionAllowed) {
        control.setValue(Number(match.input?.slice(0, -1)))
      }
      return null
    }
    return null
  };
}

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.value.newPassword === control.value.cfNewPassword
    ? null
    : { PasswordNoMatch: true };
};

export function compareValidator(value: string, matchValue: string, errorKey: string): ValidatorFn {
  const errorObject: any = {};
  errorObject[errorKey] = true;
  return (control: AbstractControl): ValidationErrors | null => {
    return control.get(value)?.value === control.get(matchValue)?.value ? null : errorObject;
  };
}

export function maxLengthRestriction(maxLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;

    if (value && value.length > maxLength) {
      control.setValue(value.slice(0, maxLength)); // Trim the value to maxLength
    }

    return null;
  };
}

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;
    if (value && /\s/.test(value)) {
      // return { 'noWhitespace': true };
      control.setValue(value.replace(' ', ''))
    }
    return null;
  };
}

export function minMaxQuantityValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let inputString = control.value;
    if (inputString?.slice(-4) === ' gal') {
      inputString = inputString?.slice(0, -4);
    }
    if (inputString >= min && inputString <= max && !isNaN(inputString)) {
      return null
    }
    return { minMax: { min: min, max: max } }
  };
}

export function quantityInputValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let doWeHaveGalInString = control.value?.slice(-4) === ' gal'
    let inputString;
    if (doWeHaveGalInString) {
      inputString = control.value.slice(0, -4);
      // console.log(inputString)
      const containsNonDigit: boolean = /[^\d]/.test(inputString);
      // console.log(containsNonDigit);
      if (containsNonDigit) {
        inputString = inputString.replace(/[^\d]/g, '');
        // console.log('containsNonDigit', inputString)
        control.setValue(inputString)
      }
    } else {
      inputString = control.value;
      const containsNonDigit: boolean = /[^\d]/.test(inputString);
      if (containsNonDigit && inputString) {
        inputString = inputString.replace(/[^\d]/g, '');
        // console.log('containsNonDigit', inputString)
        control.setValue(inputString)
      }
    }
    return null
  };
}

export function numericOnlyValidator(): ValidatorFn {
  return (control: AbstractControl): null => {
    const initialValue = control.value;

    const cleanedValue = initialValue ? initialValue.replace(/[^0-9]/g, '') : '';

    if (initialValue !== cleanedValue) {
      control.setValue(cleanedValue, { emitEvent: false });
    }

    return null;
  };
}

export function autoCapitalize(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value;

    if (value && value !== value.toUpperCase()) {
      const uppercaseValue = value.toUpperCase();
      
      control.setValue(uppercaseValue, { emitEvent: false });
    }

    return null;
  };
}