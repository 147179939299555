export enum Cookie {
    ZIP_CODE = 'zip_code',
    GALLONS = 'gallons',
    AUTH_TOKEN = 'auth_token',
    PRIVACY_CONFIRMED = 'privacy_confirmed',
    COMPANY_ID = 'company_id',
    USER_NAME = 'user_name',
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    USER_EMAIL = 'user_email',
    USER_ID = 'user_id',
    CART = 'cart',
    NAVIGATION_HISTORY = 'navigation_history',
    USER_CITY = 'user_city',
    USER_ROLE = 'user_role',
    TOKEN_EXPIRATION = 'token_expiration',
    REFRESH_TOKEN = 'refresh_token',
    SERVICE_ENABLED = 'service_enabled',
    OIL_DELIVERY_ENABLED = 'oil_delivery_enabled',
    APP_POPUP = 'app_popup',
    COMPANY_NAME = 'company_name',
    COMPANY_TRUCK = 'company_truck',
    REFERRAL_CODE = 'referral_code'
}
